<template>
    <section class="contact-us-section container" id="contact-us">
        <div class="container">
            <div class="section-name text-center mb-2">Contact With Us</div>

            <div class="contact-ways-container d-flex flex-column flex-md-row justify-content-between pt-4 p-4">
                <div class="w-100 w-md-25">
                    <h3 class="mb-3 mb-md-5">Contact Us</h3>

                    <div>
                        <ul class="list-unstyled">
                            <li class="my-2">
                                <div>
                                    <b>
                                        <span class="mx-2"><i class="fa-solid fa-phone"></i></span>
                                        Phone Number :
                                    </b> 
                                    <a href="tel:+962787774404" class="text-white">+962 7 9600 7279</a>
                                </div>
                            </li>

                            <li class="my-2">
                                <div>
                                    <b>
                                        <span class="mx-2"><i class="fa-solid fa-location-dot"></i></span>
                                         Location :
                                    </b> 
                                    <span>Jordan - Amman - Gardenz</span>
                                </div>
                            </li>

                            <li class="my-2">
                                <div>
                                    <b>
                                        <span class="mx-2"><i class="fa-solid fa-envelope"></i></span>
                                         Email :
                                    </b> 
                                    <a href="mailto:info@pulsetc.tech" class="text-white">info@pulsetc.tech</a>.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="w-100 w-md-50 mt-4 mt-md-auto">
                    <h3 class="mb-3 mb-md-5">Send email to us <i class="fa-solid fa-envelope"></i></h3>

                    <form action="#" method="post">
                        <div class="my-2">
                            <label for="floatingInput" class="fs-5">Email *</label>
                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" required>
                        </div>

                        <div class="my-2">
                            <label for="floatingSubject" class="fs-5">Subject *</label>
                            <input type="text" class="form-control" id="floatingSubject" placeholder="Subject" required>
                        </div>

                        <div class="my-2">
                            <label for="floatingMessage" class="fs-5">Message *</label>
                            <textarea class="form-control" id="floatingMessage" placeholder="Message" required></textarea>
                        </div>

                        <div class="text-end">
                            <button class="btn send-email-btn">
                                Send <i class="fa-solid fa-paper-plane"></i>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </section>
</template>

<style scoped>
    .contact-ways-container {
        width: 100%;
        min-height: 410px;
        background: linear-gradient(270deg, #003d4e, #115568);
        position: relative;
        margin-top: 90px;
        margin-bottom: 40px;
        color: white;
        box-shadow: 1px 1px 11px #2a2a2a;
        border-radius: 27px;
        border-right: 4px dotted #939393;
        border-left: 4px dotted #939393;
    }

    /*Contact Us*/
    .contact-us{
        width: 40%;
        position: absolute;
        right: 0;
        height: 100%;
        background: var(--soft-background-color);
    }
    .contact-us .icon{
        border: solid .04rem rgb(122, 114, 114);
        transition: all 0.3s ease-in-out; 
        font-size: 1.2rem;
    }
    .contact-us .icon-div{
        display: flex;
        align-items: center;
        color: white;
    }
    .contact-us .icon-div .icon {
        margin-right: 1rem;
    }
    .contact-us .icon-div:hover .icon{
        color: aquamarine;
        background-color: black ;
        transform: rotate(30deg); 
    }
    /*End Contact Us*/

    /*Contact You*/
    .contact-you{
        width: 40%;
        background: var(--secondary-background-color);
        position: absolute;
        top: -8%;
        left: 5%;
    }
    .contact-you .form-control{
        border: none;
        border-bottom: solid 1px rgba(255, 255, 255, 0.74);
        background: transparent;
        border-radius: 0;
    }
    .contact-you .form-control:focus , .contact-you .form-control:active{
        color: var(--text-color);
        outline: 0;
        background: transparent;
        border-radius: 0;
        box-shadow: none;
    }
    /*End Contact You*/

    @media only screen and (max-width: 768px) {
        .contact-ways-container{
            position: static;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
        .contact-you{
            position: static;
            width: 100%;
        }
        .contact-us {
            position: static;
            margin-bottom: 15px;
            width: 100%;
        }
    }

    .send-email-btn {
        background: #007cad; 
        width: 120px; 
        color: white;
        transition: 0.3s;
    }

    .send-email-btn:hover {
        transform: scaleX(1.1);
        color: white
    }
</style>

<script>
    export default {
        
    }
</script>