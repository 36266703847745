import ProjectsVue from '../components/Projects.vue'
import ServicesVue from '../components/Services.vue'
import ContactusVue from '../components/Contactus.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectsVue
    },
    {
      path: '/services',
      name: 'services',
      component: ServicesVue
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: ContactusVue
    },
    {
      path: '/about',
      name: 'about',
    }
  ];

export default routes
