import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false


import { createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import routes from './router/index';
const router = new VueRouter({
  routes
})

import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


// fontawesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


import vueSmoothScroll from 'vue-smooth-scroll'
Vue.use(vueSmoothScroll)

import { MotionPlugin } from '@vueuse/motion'
Vue.use(MotionPlugin)

import 'particles.js';

new Vue({
  render: h => h(App),
  pinia,
  router
}).$mount('#app')
